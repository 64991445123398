.yq-panel-banner {
  border-top: 0px;
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 64px;
  padding: 0 30px;

  .banner-content {
    // height: 100%;
    position: relative;
    padding: 40px 36px;
    background-color: #fafafa;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 64px;

    .imgContainer {
      width: 400px;
      height: 100%;
      position: absolute;
      bottom: 0px;
      right: 0px;
      z-index: 0;

      img {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: opacity 0.3s ease-in-out;

        @media (min-width: 768px) and (max-width: 1280px) {
          opacity: 0.5;
        }
      }
    }
  }

  .banner-text {
    color: var(--bs-color-gray-900);
    font-size: 24px;
    font-weight: var(--bs-font-weight-bold);
    line-height: 48px;
    max-width: 400px;
  }

  button {
    font-size: 18px;
    margin-top: 40px;
    border-radius: 8px;
    padding: 10px 28px;
  }

  @media (max-width: 767px) {
    // height: 252px;
    .banner-content {
      padding: 30px !important;
      background-image: unset !important;
      background-color: #fcfcfc !important;

      p {
        line-height: 36px !important;
        font-size: 30px !important;
        font-weight: 500;
      }

      button {
        margin-top: 20px !important;
        width: 100% !important;
        line-height: 24px;
      }

      .imgContainer {
        display: none;
      }
    }
  }
}
