.PaginationBox {
  display: flex;
  flex-wrap: wrap;

  :global {
    .page-link {
      border: none;
      margin: 0 8px 5px 0;
      font-size: 16px;
      border-radius: 2px;
      padding: 6px 14px;
      color: #212121;
    }

    .active {
      .page-link {
        color: #fff;
        background-color: var(--primary-color);
      }
    }
  }
}
