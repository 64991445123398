.page-content {
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 30px;
  margin-right: auto;
  margin-left: auto;
  .page-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 15px;

    h2 {
      font-size: 32px;
      font-weight: var(--bs-font-weight-bold);
      color: var(--bs-color-gray-900);
    }
    a:hover {
      text-decoration: unset;
      color: #003a9b;
      i {
        color: #003a9b;
      }
      .download-title {
        text-decoration: underline;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    a {
      margin-top: 11px;
      margin-bottom: 11px;
      font-size: 18px;
      color: var(--bs-color-gray-900);
      display: flex;
      align-items: center;
      i {
        font-size: 24px;
        margin-right: 8px;
        color: var(--bs-color-gray-900);
      }
      .download-title {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }

  .yq-search-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;

    .form-group {
      position: relative;
      flex: 1;
      margin-right: 20px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        margin-right: 0;
      }
    }

    .input-search {
      //初始化的input。
      #yq-search-input {
        border-radius: 4px;
        padding-left: 38px;
        color: var(--bs-color-gray-900);
        border-color: var(--bs-color-gray-900);
      }
      #yq-search-input:focus {
        border-color: #0a3b98;
      }
      .fa-clear {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        z-index: 5;
      }
      .input-search-icon {
        color: var(--bs-color-gray-900);
      }

      //pre标签需要隐藏,不然会影响到搜索框的动画
      //用input+pre定位是因为详情页的yqinput需要使用pre标签
      input + pre {
        display: none !important;
      }
    }

    .country-select-group {
      @media (max-width: 767px) {
        width: 100%;
      }
      :global {
        button.btn {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        div {
          width: 100%;
        }
      }
    }

    .dropdown-menu.open {
      max-height: 300px !important;
    }
    .dropdown-menu.inner {
      max-height: 250px !important;
    }
    .dropdown-menu {
      li a span.text {
        color: var(--bs-color-gray-900);
      }
    }

    .bs-searchbox {
      margin: 4px 8px;
      border-radius: 0.215rem;
    }
  }

  .yq-postal-group {
    margin-bottom: 10px;
    .filter-option {
      font-size: 18px;
      line-height: 24px;
      color: var(--bs-color-gray-900);
    }
    .yq-btn-flat {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 15px;
      border-color: var(--bs-color-gray-900);
      .bs-caret {
        color: var(--bs-color-gray-900);
        float: right;
      }
    }
  }

  .yq-show-carrier {
    padding: 30px 0;
    display: grid;
    margin: 0 15px;
    position: relative;
    min-height: 211px;
    a {
      text-decoration: none;
    }
    .carriers-alink {
      text-decoration: none;
    }
    figure {
      display: flex;
      margin-bottom: 40px;
      .carriers-img-box {
        width: 68px;
        height: 68px;
        margin-right: 15px;
        img {
          border-radius: 4px;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14);
          transition: all 0.3s;
          object-fit: contain;
          width: 64px;
          height: 64px;
        }
      }
    }
    figure:hover {
      img {
        transform: scale(1.07);
        box-shadow: 0 0px 16px 0px rgba(0, 0, 0, 0.06);
      }
      .icon-carrier-title {
        color: #003a9b;
        text-decoration: underline;
      }
    }
    .icon-carrier-title {
      line-height: 22px;
      font-size: 18px;
      font-weight: 500;
      color: var(--bs-color-gray-900);
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      margin: 0;
    }
    .country {
      min-height: 22px;
    }
    .icon-carrier-desc {
      color: var(--bs-color-gray-600);
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      margin: 0;
    }
    .no-data-box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .loading-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 99;
      transform: translate(-50%, -50%);
      background-color: #fff;

      .loading {
        width: 100%;
        height: 100%;
        background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232%22%20height%3D%2232%22%20fill%3D%22%23ccc%22%3E%0A%20%20%3Cpath%20opacity%3D%22.25%22%20d%3D%22M16%200%20A16%2016%200%200%200%2016%2032%20A16%2016%200%200%200%2016%200%20M16%204%20A12%2012%200%200%201%2016%2028%20A12%2012%200%200%201%2016%204%22/%3E%0A%20%20%3Cpath%20d%3D%22M16%200%20A16%2016%200%200%201%2032%2016%20L28%2016%20A12%2012%200%200%200%2016%204z%22%3E%0A%20%20%20%20%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20from%3D%220%2016%2016%22%20to%3D%22360%2016%2016%22%20dur%3D%220.8s%22%20repeatCount%3D%22indefinite%22%20/%3E%0A%20%20%3C/path%3E%0A%3C/svg%3E%0A%0A')
          center center no-repeat;
        animation-fill-mode: both;
      }

      .loading-text {
        color: #aaa;
        position: absolute;
        margin-top: 70px;
      }
    }
  }
}
